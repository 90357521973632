import { contentDefaults, styleDefaults } from "./PageDefaults";

const validatePageSchema = (pageData: any): Page => {
  const pageDefaults = { content: contentDefaults, style: styleDefaults };

  const validatedPageSchema: Page = {
    style: {
      selectedTheme: "light",
      themes: pageData.style.themes ?? pageDefaults.style.themes,
      brandColors: {
        background:
          pageData.style.brandColors?.background ??
          pageDefaults.style.brandColors.background,
        textColor:
          pageData.style.brandColors?.textColor ??
          pageDefaults.style.brandColors.textColor,
        copyColor:
          pageData.style.brandColors?.copyColor ??
          pageDefaults.style.brandColors.copyColor,
        shadowColor:
          pageData.style.brandColors?.shadowColor ??
          pageDefaults.style.brandColors.shadowColor,
      },
      gradientColors: {
        firstColor:
          pageData.style.gradientColors?.firstColor ??
          pageDefaults.style.gradientColors.firstColor,
        secondColor:
          pageData.style.gradientColors?.secondColor ??
          pageDefaults.style.gradientColors.secondColor,
      },
      sphereGradientColors: {
        firstColor:
          pageData.style.sphereGradientColors?.firstColor ??
          pageDefaults.style.sphereGradientColors.firstColor,
        lastColor:
          pageData.style.sphereGradientColors?.lastColor ??
          pageDefaults.style.sphereGradientColors.lastColor,
      },
      ogTags: {
        image: pageData.style.ogTags?.image ?? pageDefaults.style.ogTags.image,
        description:
          pageData.style.ogTags?.description ??
          pageDefaults.style.ogTags.description,
      },
    },
    content: {
      logo: pageData.content.logo !== undefined ? pageData.content.logo : "",
      logoBackgroundHidden: pageData.content.logoBackgroundHidden ?? false,
      title: pageData.content.title !== undefined ? pageData.content.title : "",
      heroVideo: {
        videoUrl:
          pageData.content.heroVideo?.videoUrl ??
          pageDefaults.content.heroVideo.videoUrl,
        coverUrl:
          pageData.content.heroVideo?.coverUrl !== undefined
            ? pageData.content.heroVideo?.coverUrl
            : pageDefaults.content.heroVideo.coverUrl,
      },
      visualizationLogo:
        pageData.content.visualizationLogo ??
        pageDefaults.content.visualizationLogo,
      sections: {
        titles: {
          about:
            pageData.content.sections?.titles?.about ??
            pageDefaults.content.sections.titles.about,
          faq:
            pageData.content.sections?.titles?.faq ??
            pageDefaults.content.sections.titles.faq,
          partners:
            pageData.content.sections?.titles?.partners ??
            pageDefaults.content.sections.titles.partners,
          cards:
            pageData.content.sections?.titles?.cards ??
            pageDefaults.content.sections.titles.cards,
          ddcCampaigns:
            pageData.content.sections?.titles?.ddcCampaigns ??
            pageDefaults.content.sections.titles.ddcCampaigns,
          videoGallery:
            pageData.content.sections?.titles?.videoGallery ??
            pageDefaults.content.sections.titles.videoGallery,
          circle:
            pageData.content.sections?.titles?.circle ??
            pageDefaults.content.sections.titles.circle,
          prizes:
            pageData.content.sections?.titles?.prizes ??
            pageDefaults.content.sections.titles.prizes,
        },
        about:
          pageData.content.sections?.about ??
          pageDefaults.content.sections.about,
        cards: pageData.content.sections.cards.map((card: Partial<Card>) => {
          return {
            title: card.title ?? "",
            coverUrl: card.coverUrl ?? "",
            text: card.text ?? "",
          };
        }),
        quoteCard: {
          quoter: {
            name:
              pageData.content.sections.quoteCard.quoter.name !== undefined
                ? pageData.content.sections.quoteCard.quoter.name
                : "",
            photo:
              pageData.content.sections.quoteCard.quoter.photo !== undefined
                ? pageData.content.sections.quoteCard.quoter.photo
                : "",
            title:
              pageData.content.sections.quoteCard.quoter.title !== undefined
                ? pageData.content.sections.quoteCard.quoter.title
                : "",
          },
          quote:
            pageData.content.sections.quoteCard.quote !== undefined
              ? pageData.content.sections.quoteCard.quote
              : "",
        },
        faqQuestions:
          pageData.content.sections.faqQuestions ??
          pageDefaults.content.sections.faqQuestions,
        videoGallery:
          pageData.content.sections.videoGallery !== undefined
            ? pageData.content.sections.videoGallery.map(
                (item: Partial<GalleryItem>) => ({
                  videoUrl: item.videoUrl ?? "",
                  coverUrl: item.coverUrl ?? "",
                  isVideo: item.isVideo ?? true,
                })
              )
            : [],
        brandLogos: pageData.content.sections.brandLogos ?? [],
      },
    },
  };

  return validatedPageSchema;
};

export default validatePageSchema;
