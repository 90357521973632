import { defineStore } from "pinia";
import usePageData from "./PageData";
import usePageSummary from "./PageSummary";

export const usePageStore = defineStore("CirclePageStore", () => {
  const data = usePageData();
  const summary = usePageSummary();

  const onEditMode = ref(true);

  function toggleEditMode(): void {
    onEditMode.value = !onEditMode.value;
  }

  return {
    ...data,
    ...summary,
    onEditMode,
    toggleEditMode,
  };
});
