export const styleDefaults: PageStyle = {
  selectedTheme: "light",
  themes: {
    light: {
      textColor: "#fff",
      shadowColor: "#000",
      backgroundColor: "#fff",
      borderColor: "#000",
    },
    dark: {
      textColor: "#000",
      shadowColor: "#fff",
      backgroundColor: "#000",
      borderColor: "#fff",
    },
  },
  brandColors: {
    background: "#061720",
    textColor: "#E2753B",
    copyColor: "#fff",
    shadowColor: "#fff",
  },
  gradientColors: {
    firstColor: "#1c5776",
    secondColor: "#288c9f",
  },
  sphereGradientColors: {
    firstColor: "#112a37",
    lastColor: "#24768600",
  },
  ogTags: {
    image: "",
    description: "",
  },
};

export const contentDefaults: PageContent = {
  logo: "/images/mock/sl-logo.png",
  logoBackgroundHidden: false,
  title: "",
  heroVideo: {
    videoUrl: "https://vimeo.com/675761656",
    coverUrl: "/images/mock/video-cover.jpeg",
  },
  visualizationLogo: "",
  sections: {
    titles: {
      about: "About",
      faq: "FAQ",
      partners: "Our Partners",
      cards: "Your Monthly Donation Support",
      ddcCampaigns: "Our Initiatives",
      videoGallery: "",
      circle: "The Power of Collective Giving",
      prizes: "Prizes",
    },
    about: ` 
    Help create a world where our oceans are full of life.<br><br>
    Join The Tide, a passionate community invested in the health and sustainability of our oceans.<br><br>
    Help create a world where our oceans are full of life.
    `,
    cards: [
      {
        title: "1 / Expeditions",
        coverUrl: "/images/mock/cards/1.jpeg",
        text: "First, we lead a team of the world’s best photographers and filmmakers on expedition to capture the beaury and the threats below the surface of our oceans.",
      },
      {
        title: "2 / Campaigns",
        coverUrl: "/images/mock/cards/2.jpeg",
        text: "With your help, we spread the word. Using the incredible media we capture out on expedition we fuel global campaigns that trigger lasting and sustainable change.",
      },
      {
        title: "3 / Solutions",
        coverUrl: "/images/mock/cards/3.jpeg",
        text: "And we don’t stop there. As a community we amplify and fund inspiring projects around the world, creating healthy and abundant oceans, one solution at a time.",
      },
    ],
    quoteCard: {
      quoter: {
        name: "Cristina Mittermeier",
        photo: "/images/mock/founder.jpeg",
        title: "Co-founder of SeaLegacy",
      },
      quote:
        "“Extraordinary opportunities exist to restore and sustainably develop our oceans in order to protect them and sustain all life on this planet.”",
    },
    faqQuestions: [
      {
        title: "Where does my donation go?",
        answer:
          "All donations go to Sea Leagacy via the Dollar Donation Club non-profit, “Dollar Donation Club Fund.”",
      },
      {
        title: "How much of my donation goes to the cause?",
        answer:
          "100% of your donation will go to Sea Legacy, however Visa & Mastercard do charge a card processing fee that we are currently unable to avoid and invite donors to cover.",
      },
      {
        title: "How do I know if this will actually make an impact?",
        answer:
          "Our team of world renowned scientists, innovators and researchers put a lot of time into vetting organizations to ensure every dollar makes a measurable and verifiable impact. You will be able to verify what happened with your donation on your Donation Tracker--where you can follow progress updates to see your donation’s impact in action.",
      },
      {
        title: "Is there a fee?",
        answer:
          "No way dude! If you think we’re doing a good job, you can consider giving us a micro-tip on top of your donation. We’ll still love you even if you don’t. Larger donors and brand donors help us cover operating costs with a 10% administrative fee on their donations.",
      },
    ],
    videoGallery: [
      {
        videoUrl: "https://vimeo.com/675761656",
        coverUrl: "/images/mock/video-cover.jpeg",
        isVideo: true,
      },
      {
        videoUrl: "https://vimeo.com/675761656",
        coverUrl: "/images/mock/video-cover.jpeg",
        isVideo: true,
      },
      {
        videoUrl: "https://vimeo.com/675761656",
        coverUrl: "/images/mock/video-cover.jpeg",
        isVideo: true,
      },
    ],
    brandLogos: [],
  },
};

export const leaderboardDefaults: Leaderboard = {
  recentDonors: [],
  topDonors: [],
};

export const pageDefaults: Page = {
  content: contentDefaults,
  style: styleDefaults,
};
