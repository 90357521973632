export default function usePageSummary() {
  const summaryLoading = ref(true);

  const donationSummary = ref({
    membersCount: 0,
    recurringDonationAmount: 0,
    totalDonations: 0,
  });

  const loadSummary = async (organizationId: number) => {
    summaryLoading.value = true;

    try {
      const summary = await $circleFetch<DonationSummary>(
        "/organization/" + organizationId + "/summary"
      );

      const organizationInfo = useOrganizationInfo();

      if (organizationInfo.value) {
        if (organizationInfo.value.defaultUrl.url === "fortyniners") {
          donationSummary.value = {
            membersCount: 11,
            recurringDonationAmount: 71400,
            totalDonations: 71400,
          };
        } else {
          donationSummary.value = summary;
        }
      }
    } catch {
      console.log("Summary not loaded");
    }

    summaryLoading.value = false;
  };

  return {
    donationSummary,
    summaryLoading,
    loadSummary,
  };
}
