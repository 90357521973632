export function useOrganizationInfo() {
  const pageStore = usePageStore();
  const staffStore = useStaffStore();

  const organizationInfo = computed(() => {
    if (pageStore.currentOrganization) {
      return pageStore.currentOrganization;
    } else if (staffStore.info) {
      return staffStore.info.organization;
    }

    return null;
  });

  return organizationInfo;
}